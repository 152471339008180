@use 'sass:map';
@use '/sass/vars';
@use '/sass/atoms/typography/body';

.Footer {
  background-color: map.get(vars.$colors, text-black);
  @media screen and (min-width: 1231px) {
    padding-top: 60px;
  }
  padding-top: 24px;
  padding-bottom: 24px;
  @extend %body--large;
  color: map.get(vars.$colors, text-white);

  &__container {
    max-width: 1280px + 60px;
    width: 100%;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  &__bottom {
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      row-gap: 0;
    }
    @extend %body--small;
    color: map.get(vars.$colors, rea-gray);
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 20px;
  }

  hr {
    width: 100%;
    border-top: 1px solid map.get(vars.$colors, rea-gray-secondary);
    margin-top: 24px;
    margin-bottom: 24px;

    &:last-of-type {
      @media screen and (min-width: 1024px) {
        display: block;
      }
      display: none;
    }
  }

  &__bottom-links {
    padding: 0;
    list-style: none;

    li {
      display: inline;

      &::before {
        content: " | ";
      }

      &:first-child {
        &::before {
          content: none;
        }
      }
    }
  }

  &__privacy {
    a {
      display: inline-flex;
      align-items: center;
    }

    img {
      max-width: initial;
      height: initial;
      margin-right: 5px;
    }
  }

  &__grid {
    @media screen and (min-width: 1024px) {
      display: grid;
    }
    padding-top: 36px;
    padding-bottom: 36px;
    row-gap: 80px;
    column-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    display: none;
  }

  &__grid-item-heading {
    @extend %body--large;
    text-transform: uppercase;
    opacity: 0.6000000238418579;
    color: map.get(vars.$colors, rea-gray-tri);
    margin-bottom: 40px;
  }

  &__grid-item-list {
    list-style: none;
    padding: 0;

    li + li {
      padding-top: 16px;
    }
  }

  &__top {
    @media screen and (min-width: 376px) {
      grid-template-columns: minmax(200px, 1fr) minmax(auto, 1fr);
      grid-template-areas:
        "logo text"
        "social-mobile .";
      column-gap: 30px;
    }
    @media screen and (min-width: 1231px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: "logo text .";
      padding-bottom: 36px;
    }

    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "logo"
      "text"
      "social-mobile";
    row-gap: 20px;
  }

  &__text {
    @media screen and (min-width: 1231px) {
      grid-template-columns: 1fr 88px;
      grid-template-areas: "info social";
      column-gap: 40px;
      grid-column: span 2;
    }
    grid-area: text;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "social";
  }

  &__logo {
    @media screen and (min-width: 376px) {
      justify-content: flex-start;
    }
    grid-area: logo;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    @media screen and (min-width: 1231px) {
      display: flex;
    }
    grid-area: info;
    align-items: center;
    column-gap: 40px;
    display: none;
  }

  &__info-mobile {
    @media screen and (min-width: 376px) {
      grid-column: span 2;
    }
    @media screen and (min-width: 1231px) {
      display: none;
    }
    grid-area: social-mobile;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__fa-icon {
    // NOTE: no idea why it's flipped the wrong way
    transform: scaleX(-1);
  }

  &__social-icons {
    font-size: 32px;
    background-color: transparent;
  }

  &__social {
    @media screen and (min-width: 376px) {
      justify-content: flex-end;
    }
    grid-area: social;
    display: flex;
    align-items: center;
    column-gap: 24px;
    justify-content: center;
  }

  &__logos {
    @media screen and (min-width: 1024px) {
      justify-content: flex-end;
      margin-top: -59px;
      margin-bottom: 0;
    }
    display: flex;
    justify-content: center;
    margin-top: 0;
    column-gap: 9px;
    margin-bottom: 20px;
  }
}
